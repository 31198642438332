import { render, staticRenderFns } from "./answerProblem.vue?vue&type=template&id=a2a174be&scoped=true&"
import script from "./answerProblem.vue?vue&type=script&lang=js&"
export * from "./answerProblem.vue?vue&type=script&lang=js&"
import style0 from "./answerProblem.vue?vue&type=style&index=0&id=a2a174be&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2a174be",
  null
  
)

export default component.exports