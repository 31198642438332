var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('Breadcrumb'),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"body answerBox"},[(
        !_vm.loading &&
        (_vm.answers[_vm.question.id] = _vm.answers[_vm.question.id] || {
          answer: '',
          quest_id: _vm.question.id,
        })
      )?_c('div',[(_vm.question.type == 2)?[(_vm.question.is_select == 2)?_c('PDT',{attrs:{"alone":true,"status":_vm.status,"optionsData":{
            isAnswer: _vm.isAnswer,
            topicData: { ..._vm.question, options: _vm.detail.options },
          }},model:{value:(_vm.answers[_vm.question.id].answer),callback:function ($$v) {_vm.$set(_vm.answers[_vm.question.id], "answer", $$v)},expression:"answers[question.id].answer"}}):_vm._e(),(_vm.question.is_select == 0)?_c('TKT',{attrs:{"alone":true,"optionsData":{ isAnswer: _vm.isAnswer, topicData: _vm.question },"status":_vm.status},model:{value:(_vm.answers[_vm.question.id].answer),callback:function ($$v) {_vm.$set(_vm.answers[_vm.question.id], "answer", $$v)},expression:"answers[question.id].answer"}}):_vm._e(),(_vm.question.is_select == 3)?_c('DUOXT',{attrs:{"alone":true,"optionsData":{
            isAnswer: _vm.isAnswer,
            topicData: { ..._vm.question, options: _vm.detail.options },
          },"status":_vm.status},model:{value:(_vm.answers[_vm.question.id].answer),callback:function ($$v) {_vm.$set(_vm.answers[_vm.question.id], "answer", $$v)},expression:"answers[question.id].answer"}}):_vm._e(),(_vm.question.is_select == 1)?_c('DXT',{attrs:{"alone":true,"optionsData":{
            isAnswer: _vm.isAnswer,
            topicData: { ..._vm.question, options: _vm.detail.options },
          },"status":_vm.status},model:{value:(_vm.answers[_vm.question.id].answer),callback:function ($$v) {_vm.$set(_vm.answers[_vm.question.id], "answer", $$v)},expression:"answers[question.id].answer"}}):_vm._e()]:_vm._e(),(_vm.question.type == 1)?_c('BCT',{ref:"BCT",attrs:{"alone":true,"optionsData":{ isAnswer: false, topicData: _vm.question }},model:{value:(_vm.answers[_vm.question.id].answer),callback:function ($$v) {_vm.$set(_vm.answers[_vm.question.id], "answer", $$v)},expression:"answers[question.id].answer"}}):_vm._e(),(_vm.question.type == 3)?[_c('div',{staticClass:"question_title"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.question.title)}})]),_c('div',{directives:[{name:"code-html",rawName:"v-code-html",value:(_vm.question.description),expression:"question.description"}]}),_vm._l((_vm.detail.children),function(v,i){return _c('div',{key:i},[(v.is_select == 2)?_c('PDT',{attrs:{"alone":true,"optionsData":{ isAnswer: _vm.isAnswer, topicData: v },"status":_vm.status},model:{value:(
              (_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }).answer
            ),callback:function ($$v) {_vm.$set((_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }), "answer", $$v)},expression:"\n              (answers[v.id] = answers[v.id] || {\n                answer: '',\n                quest_id: v.id,\n              }).answer\n            "}}):_vm._e(),(v.is_select == 0)?_c('TKT',{attrs:{"alone":true,"optionsData":{ isAnswer: _vm.isAnswer, topicData: v },"status":_vm.status},model:{value:(
              (_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }).answer
            ),callback:function ($$v) {_vm.$set((_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }), "answer", $$v)},expression:"\n              (answers[v.id] = answers[v.id] || {\n                answer: '',\n                quest_id: v.id,\n              }).answer\n            "}}):_vm._e(),(v.is_select == 3)?_c('DUOXT',{attrs:{"alone":true,"optionsData":{ isAnswer: _vm.isAnswer, topicData: v },"status":_vm.status},model:{value:(
              (_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }).answer
            ),callback:function ($$v) {_vm.$set((_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }), "answer", $$v)},expression:"\n              (answers[v.id] = answers[v.id] || {\n                answer: '',\n                quest_id: v.id,\n              }).answer\n            "}}):_vm._e(),(v.is_select == 1)?_c('DXT',{attrs:{"alone":true,"optionsData":{ isAnswer: _vm.isAnswer, topicData: v },"status":_vm.status},model:{value:(
              (_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }).answer
            ),callback:function ($$v) {_vm.$set((_vm.answers[v.id] = _vm.answers[v.id] || {
                answer: '',
                quest_id: v.id,
              }), "answer", $$v)},expression:"\n              (answers[v.id] = answers[v.id] || {\n                answer: '',\n                quest_id: v.id,\n              }).answer\n            "}}):_vm._e()],1)})]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"save-btn"},[_c('div',{staticClass:"save",on:{"click":_vm.confirm}},[_vm._v("提交")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }